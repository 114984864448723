<template>
  <my-upload
    :width="300"
    :height="300"
    :max-size="1024"
    :params="params"
    :url="config.url"
    v-model="dialog"
    :no-circle="true"
    :no-square="true"
    lang-type="pt-br"
    img-format="jpg, jpeg"
    :field="config.field"
    :headers="config.headers"
    @crop-upload-fail="cropUploadFail"
    @crop-upload-success="cropUploadSuccess"
  />
  <span class="text-grey-6" style="font-size: 11pt;">{{
    $t("account.pic_dimensions")
  }}</span>
  <span class="text-grey-6" style="font-size: 11pt;">{{
    $t("account.pic_size")
  }}</span>
  <div class="text-center q-pb-md q-pt-md">
    <q-btn
      flat
      dense
      icon="image"
      color="primary"
      class="q-ma-sm"
      :label="$t('account.change_photo')"
      @click="showUploader()"
    />
    <q-btn
      flat
      dense
      color="red"
      icon="delete"
      class="q-ma-sm"
      :label="$t('account.delete_photo')"
      @click="deletePhotoClick()"
      :disable="inRequest || photo === null"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyError, notifyInfo } from "@/shared/helpers/notify";
import myUpload from "vue-image-crop-upload";
import { ref } from "vue";

const accountStore = createNamespacedHelpers("account"),
  authStore = createNamespacedHelpers("auth");

export default {
  name: "UploadPhoto",

  components: { myUpload },

  setup() {
    const dialog = ref(false),
      { config } = accountStore.useGetters({
        config: "getImageUploadConf",
      }),
      { photo } = authStore.useGetters({ photo: "getPhoto" }),
      { setUserPartnerAction } = authStore.useActions(["setUserPartnerAction"]),
      { removeAccountPhoto } = accountStore.useActions(["removeAccountPhoto"]),
      inRequest = ref(false);

    const cropUploadSuccess = (partner) => setUserPartnerAction(partner),
      cropUploadFail = () => {};

    const deletePhotoClick = () => {
      inRequest.value = true;
      removeAccountPhoto()
        .then(() => notifyInfo("notify.success"))
        .catch(() => notifyError("notify.unprocessed_error_on_delete"))
        .finally(() => (inRequest.value = false));
    };

    const showUploader = () => (dialog.value = true),
      srcFileSet = () => {
        // tamanho da imagem como 1mb
        if (photo && photo.length > 0) return photo[0].url;
      };

    return {
      photo,
      dialog,
      config,
      inRequest,
      srcFileSet,
      showUploader,
      cropUploadFail,
      deletePhotoClick,
      cropUploadSuccess,
    };
  },
};
</script>
